<template>
  <div class="paging">
    <!-- v-if="end>1" -->
    <div class="pag-wrap" v-if="end>1">
      <span class="prev" :class="{'forbid':present===1}" @click="handlePrev">{{ $t('paging.last') }}</span>
      <ul class="pag-list":class="totalNum>=7 ? '' :'pag-list1' ">
        <li class="pag-item" v-for="(item,i) in totalNum" :key="i" @click="handleOn(item)">
          <span class="pag-txt" v-if="item!='...'" :class="{'txt-show':item==present}">{{ item }}</span>
          <span class="pag-omit" v-else>{{ item }}</span>
        </li>
      </ul>
      <span class="next" :class="{'forbid':present===end}" @click="handleNext">{{ $t('paging.next') }}</span>

      <div class="inp-wrap">
        <p class="hint">{{ $t('paging.go') }}</p>
        <input class="pag-inp" ref="goNum" :value="present"/>
        <p class="hint">{{ $t('paging.page') }}</p>
      </div>
      <span class="pag-btn" @click="handelNum">{{ $t('paging.zhgo') }}</span>
      <p class="total-wrap">
        {{ $t('paging.toPage') + ':' }}
        <span class="total">{{ end }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    total: {  // 总条数
      type: Number,
      default() {
        return 1
      }
    },
    pageSize: {  // 条数
      type: Number,
      default() {
        return 10
      }
    },
    pageNum: { // 页数
      type: Number,
      default() {
        return 1
      }
    },
    present: {
      type: Number,
      default() {
        return 1
      }
    }
  },
  data() {
    return {
     // present: 1  // 当前选中的页数
    }
  },
  computed: {
    end() {
      return Math.ceil(this.total / this.pageSize) // 最后一页//一共几页
    },
    totalNum() {  //分页数
      let start = this.present; // 当前页
      let end = this.end;
      // console.log('会重新触发吗',end)
      if (end < 10) return end; // 出现省略号 是大于10的时候
      if (start <= 5) {  // 当前页码小于5
        return [1, 2, 3, 4, 5, 6, '...', end]
      } else if (start > end - 5) {  // 当前页码大于等于最后一页-5
        return [1, '...', end - 5, end - 4, end - 3, end - 2, end - 1, end]
      } else {
        return [1, '...', start - 3, start - 2, start - 1, start, start + 1, start + 2, start + 3, '...', end]
      }
    }
  },

  methods: {
    //  当前选中的分页数
    handleOn(item) {
      if ('...' == item) return;
      this.present = item
    },
    // 上一页
    handlePrev() {
      if (this.present == 1) return;
      this.present--
    },
    // 下一页
    handleNext() {
      if (this.present == this.end) return;
      this.present++
    },
    // 手动输入页数
    handelNum() {
      let num = Number(this.$refs.goNum.value);
      if (!this.isNumber(num)) { // 排除NaN 的情况  必须是数字
        this.$message.error('页索引无效')
        return;
      }

      if (num > this.end) {
        this.$message.error('输入页索引超出范围')
        return;
      }
      ;
      this.present = num;
      // console.log(this.$refs.goNum.value)
    },
    isNumber(num) { // 排除NaN 的情况  必须是数字
      // console.log(typeof num === 'number' && !isNaN(num))
      return typeof num === 'number' && !isNaN(num)
    }
  },
  watch: {
    present(newNum, oldNum) {
      // console.log(newNum,oldNum)
      window.scrollTo(100, 100);
      this.$emit('change-count', newNum)  // 监听分页变化
    }
  }
}
</script>

<style lang="less" scoped>
.paging {
  width: 100%;

  .pag-wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    .prev, .next {
      padding: 10px;
      display: inline-block;
      background: #f5f5f5;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 500;
      color: #333333;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #B23E3E;
      }
    }

    .forbid {
      cursor: not-allowed;

      &:hover {
        color: #333333;
      }
    }


    .pag-list {
      margin: 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      .pag-item {
        margin: 0 10px;
        width: 40px;
        line-height: 40px;

        .pag-txt {
          display: inline-block;
          width: 100%;
          height: 100%;
          background: #f5f5f5;
          text-align: center;
          border: 1px solid #d7dbe0;
          color: #333333;
          border-radius: 3px;
          cursor: pointer;

          &:hover {
            background: #B23E3E;
            color: #ffffff;
          }

        }

        .txt-show {
          background: #B23E3E;
          color: #ffffff;
        }

        .pag-omit {
          display: inline-block;
          width: 100%;
          height: 100%;
          font-size: 20px;
          text-align: center;
          // background: #B23E3E;
        }
      }
    }

    .inp-wrap {
      margin-left: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      .pag-inp {
        margin: 0 5px;
        box-sizing: border-box;
        padding: 10px;
        width: 63px;
        height: 40px;
        background: #ffffff;
        text-align: center;
        border: 1px solid #e6e6e6;
        border-radius: 3px;
      }

      .hint {
        font-size: 12px;
        font-weight: 400;
        color: #333333;
      }
    }

    .pag-btn {
      margin: 0 20px;
      width: 80px;
      line-height: 38px;
      text-align: center;
      background: #B23E3E;
      border-radius: 3px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }

    .total-wrap {
      margin-left: 20px;
      font-size: 18px;
      font-weight: 400;
      color: #484848;

      .total {
        color: #B23E3E;
        font-size: 20px;
        font-weight: 500;
      }
    }
  }

}


@media only screen and (max-width: 750px) {
  .paging {
    // margin-top: 5vw;
    width: 90vw;

    .pag-wrap {
      flex-wrap: wrap;
      // background-color: aqua;
      .prev, .next {
        padding: 2vw 3vw;
        font-size: 3.5vw;
        border-radius: 1vw;
      }
      .pag-list {
        margin: 5vw;
        width: 90vw;
        justify-content: start !important;
        overflow: scroll;

        .pag-item {
          margin: 0 2vw;
          width: 9vw;
          line-height: 9vw;
          .pag-txt {
            width: 9vw !important;
          }
        }
      }
      .pag-list1{
        justify-content: center !important;
      }
      .inp-wrap {
        width: 40vw;

        .pag-inp {
          margin: 0 3vw;
          width: 20vw;
          height: 8vw;
          border: 1px solid #e6e6e6;
          font-size: 3.5vw;

        }

        .hint {
          font-size: 3.5vw;
        }
      }

      .pag-btn {
        width: 10vw;
        line-height: 9vw;
        font-size: 2.5vw;

      }

      .total-wrap {
        margin-left: 20px;
        font-size: 3.5vw;

        .total {
          font-size: 3.5vw;
        }
      }
    }
  }
}

</style>
