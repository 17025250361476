<template>
  <div class="nav">
    <div class="nav-item" v-if="tabName">
      <p class="nav1">
        <router-link :to="tabName[0].path">
          {{ tabName[0].oneTab }}
        </router-link>
      </p>
      <span class="icon"> » </span>
      <p class="nav2">
        <router-link :to="tabName[1].path">
          {{ tabName[1].twoTab }}
        </router-link>
      </p>
      <span class="icon"> » </span>
      <p class="nav3">
        <router-link :to="tabName[2].path">
          {{ tabName[2].threeTab }}
        </router-link>
      </p>
    </div>
    <div class="title" v-if="tabName[3]">
<!--      <img class="entry-img" src="../static/fuyimg/Group195.png">-->
      <h3 class="tit-h3">
        {{ tabName[3].titName }}
      </h3>
<!--      <img class="entry-img" src="../static/fuyimg/Group196.png">-->
    </div>
    <!--  公众号 -->
    <div class="md-title" v-if="tabName[3]">
      <img class="entry-img" src="../static/img/md_titi.png">
      <h3 class="tit-h3">
        {{ tabName[3].titName }}
      </h3>
      <img class="entry-img" src="../static/img/md_titi.png">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabName: {  // 导航名称
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.nav {

  // width: 1364px;
  // width:1460px;

  .nav-item {
    display: flex;
    align-items: center;
    // justify-content: center;
    width: 1460px;

    line-height: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #303030;
    cursor: pointer;

    .icon {
      width: 30px;
      line-height: 20px;
      font-size: 16px;
      text-align: center;
      // background-color: pink;
    }

  }

  .title {
    position: relative;
    top: 0;
    left: 0;
    margin: 0 auto;
    margin-top: 24px;
    display: flex;
    align-items: center;
    width: 1360px;
    height: 34px;
    background:url("../static/icon/Group 315@2x.png")  no-repeat;
    background-size: 100% 100%;
    // border: 1px solid pink;
    &::after, &::before {
      position: absolute;
      top: 24px;
      content: '';
      width: 20px;
      height: 1246px;
      // background-color: rosybrown;
      // background: linear-gradient(180deg, #E2E2E2 30%, #EAEAEA 60%, #ffffff 100%);
    }

    // &::after{
    //   background: url('../static/img/droop_left.png') no-repeat;
    //   background-size: 100%;
    //   left: 20px;
    // }
    // &::before{
    //   background: url('../static/img/droop_right.png') no-repeat;
    //   background-size: 100%;
    //   right: 20px;
    // }
    .entry-img {
      width: 512px;
      // background-color: blanchedalmond;
    }

    .tit-h3 {
      width: 100%;
      height: 100%;
      line-height: 20px;
      text-align: center;
      font-size: 26px;
      font-weight: 500;
      color: #303030;

    }
  }

  .md-title {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .nav {
    margin-top: 5vw;
    margin-bottom: 5vw;
    width: 90vw;

    .nav-item {
      display: none;
    }

    .title {
      display: none;
    }

    .md-title {
      // background-color: aqua;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .entry-img {
        height: 5vw;
        width: 27vw;
      }

      .tit-h3 {
        width: 26vw;
        margin: 0 5vw;
        font-size: 5vw;
        font-weight: 500;
        color: #303030;
        text-align: center;
      }
    }
  }
}

</style>
