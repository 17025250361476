

import {
  getToken
} from "@/apis/token.js"

export default {
  data() {
    return {
      // webImg:'https://www.qsxbwg.com/manage/',
      webImg:'/manage/',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    }
  },
  methods: {
    throttle(fn, gapTime) {
      if (gapTime == null || gapTime == undefined) {
        gapTime = 500  // 默认500毫秒
      }
      let _nowTime = +new Date()
      if (_nowTime - this._lastTime > gapTime || !this._lastTime) {
        fn()
        this.$message.success('刷新成功')
        this._lastTime = _nowTime
      }
    },

    // 检测是否 登录
    checkUser(){
      if(!getToken()){
        return false
      }
      return true

    },
    goPage(url,obj,goType){
      // url  跳转路由地址
      // obj  路由参数对象
      //goType  是否跳转打开新窗口  '_blank'
        if (!url) return ; // 没有路径 弹出
        if(goType=='_blank'){
          // 跳转新窗口
          let {href} = this.$router.resolve({
            path:url,
            query:obj
          })
          window.open(href,'_blank')
        }else{
          // 当前页面打开
          this.$router.push({
            path:url,
            query:obj
          })
        }
    },
    // 年月日格式
    formateTime(timestamp) {
			return this.dateFtt('yyyy-MM-dd', new Date(timestamp))
		},
    //  格式化时间
    dateFtt(fmt, date) { //author: meizz
			var o = {
				"M+": date.getMonth() + 1, //月份
				"d+": date.getDate(), //日
				"h+": date.getHours(), //小时
				"m+": date.getMinutes(), //分
				"s+": date.getSeconds(), //秒
				"q+": Math.floor((date.getMonth() + 3) / 3), //季度
				"S": date.getMilliseconds() //毫秒
			};
			if (/(y+)/.test(fmt))
				fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
			for (var k in o)
				if (new RegExp("(" + k + ")").test(fmt))
					fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k])
						.length)));
			return fmt;
		},
    // 公众号 解决图片
    formatRichText(html) { //控制富文本图片大小
      let newContent = html.replace(/<img[^>]*>/gi, function(match, capture) {
        match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
        // match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
        // match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
        return match;
      });
      newContent = newContent.replace(/style="[^"]+"/gi, function(match, capture) {
        match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi, 'max-width:100%;');
        return match;
      });
      newContent = newContent.replace(/<br[^>]*\/>/gi, '');

        newContent = newContent.replace(/\<img/gi,
          '<img style="max-width:100%;height:auto;display:inline-block;margin:10rpx auto;"');


      return newContent;
    },
    // 判断浏览器函数
    isMobile(){
      if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
      return true; // 移动端
      }else{
      return false; // PC端
      }
    }
  }

}
