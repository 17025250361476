import axios from 'axios'
import {Notification} from 'element-ui'
import QS from 'qs'
import md5 from 'js-md5'
import {getToken, isMobile, removeToken} from '@/apis/token.js'
import router from '@/router/index'

// create an axios instance
const service = axios.create({

  // baseURL: 'http://fuyang.vaiwan.com/api/',
  baseURL: `${process.env.VUE_APP_BASE_API}`, // url = base url + request url
  timeout: 50000 // request timeout，
})

// request interceptor
service.interceptors.request.use(
  config => {
    CalcuMD5(QS.parse(config.data)).then(signMD5 => {
      // do something before request is sent
      const token = getToken()
      // console.log(token)
      if (token) {
        config.headers['token'] = token
      }
      config.headers['Yw-Sign'] = signMD5
      config.headers['Yw-Time'] = (Date.parse(new Date())) / 1000
    })
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    // 统一定义状态码为200时，表示请求成功，其他请求失败
    switch (response.data.code) {
      //成功
      case 200:
        return Promise.resolve(response)
      case 401:
        removeToken()
        // 401 重新登录
        if (isMobile()) {  //  公众号
          router.push({path: '/mhome'})
        } else {
          if (!response.config.params.NO_LOGIN) {
            //  pc
            router.replace('/login')
          }
        }
        return Promise.reject(response)
      case 400:
        // Notification.error({
        //   message: response.data.msg
        // })
        return response
      default:
        //失败
        // Notification.error({
        //   message:  response.data.msg
        // })
        return Promise.reject(response);
    }

  },
  error => {
    const res = error.response.data
    const status = error.response.status
    switch (status) {
      case 500:
        if (res.message && !isMobile()) {
          Notification.error({
            message: res.message
          })
        }
        return Promise.reject(new Error(res.message || 'Error'))
      case 401:
        removeToken()
        // 401 重新登录
        if (isMobile()) {  //  公众号
          router.push({path: '/mhome'})
        } else {     //  pc
          if (!error.response.config.params.NO_LOGIN) {
            //  pc
            router.replace('/login')
          }
        }
      case 422:
        if (res.message && !isMobile()) {
          Notification.error({
            message: res.message
          })
        }
        return Promise.reject(new Error(res.message || 'Error'))
      default:
        return Promise.reject(error)
    }
  }
)

/**
 * 作者：TKE
 * 时间：2019/12/17 0017
 * 功能：对外接口
 * 描述：
 */
export default function request(options) {
  // 数据处理
  const params = options.data || {}
  const method = options.method.toUpperCase()
  const url = options.url
  const responseType = options.responseType || 'json'
  const ContentType = options.ContentType || 'application/json;charset=UTF-8'
  //  'application/x-www-form-urlencoded;application/vnd.ms-excel;charset=UTF-8'
  if (method === 'GET') {
    return service({
      url,
      method,
      params,
      responseType
    })
  } else if (method === 'POST') {
    axios.defaults.headers.post['Content-Type'] = ContentType
    const data = params//QS.stringify(params)
    return service({
      url,
      method,
      data,
      responseType
    })
  } else if (method === 'PUT' || method === 'PATCH') {
    axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

    const data = QS.stringify(params)
    return service({
      url,
      method,
      data,
      responseType
    })
  } else if (method === 'DELETE') {
    return service({
      url,
      method,
      params,
      responseType
    })
  }
}

/**
 * 作者： TKE
 * 时间： 2019/12/17 0017
 * 功能：md5加密请求体
 * 描述：
 */
function CalcuMD5(obj) {
  return new Promise(resolve => {
    // 如果不是对象  也就是说使用的GET和DELETE请求 那么不用加密  直接返回空
    const key = process.env.VUE_APP_AESKEY
    let sign = ''
    if (Object.prototype.toString.call(obj) !== '[object Object]') {
      sign = key + '--' + key
    } else {
      if (JSON.stringify(obj) === '{}') {
        sign = key + '--' + key
      } else {
        Object.keys(obj).sort().forEach((k) => {
          sign += key + k + '--' + obj[k] + key
        })
      }
    }
    // console.warn('签名==>'+sign);
    resolve(md5(sign).toUpperCase())
  })
}
