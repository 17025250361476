module.exports = {
  language: {
    name: 'English'
  },
  // 首页导航栏
  navbar: {
    register: '登录',

    home: '首页',

    overview: '概况',
    profile: '本馆简介',
    orgzt: '组织机构',
    person: '管理人员',
    events: '大事掠影',

    consult: '资讯',
    news: '新闻资讯',
    partybuilding: '党建专题',
    notice: '本馆公告',

    exhibition: '展览',
    show: '常设展览',
    recommendation: '新展推荐',
    review: '展览回顾',
    overseasexhibition: '赴外展览',
    museum: '全景博物馆',


    collection: '藏品',
    special: '特别推荐',
    boutique: '馆藏精品',
    digital: '数字藏品',
    total: '藏品总目',
    search: '馆藏查询',
    donate: '捐赠园地',

    study: '研究',
    work: '期刊论著',
    paper: '学术论文',
    project: '科研项目',
    menuType9: '学术委员会',

    fullChain: '活动',
    dcrwcac: '活动预告',
    exhibittion: '社教专题',
    activity: '活动讲座',
    other: '其他活动',

    service: '服务',
    information: '票务信息',
    instructions: '游览须知',
    map: '导览地图',
    products: '文化创艺产品',
    traffic: '交通路线',
    recommend: '志愿者之窗',
    apply: '志愿者申请',
    friends:'博物馆之友',
    contactus: '联系我们',

    platform: '数字化平台',
    online: '在线咨询',
    sm:"查看更多",
    links:'友情链接',
  },
  //其他
  mess: {
    cy:'创意来源',
    sl:'设计理念',
    cr: '3D文物',
    works: '期刊论著',
    thesis: '学术论文',
    times: '星期一闭馆，节假日正常开放，期间逢周一顺延一天闭馆。',
    status: '状态',
    order: '预约',
    need: '需要预约',
    noneed: '无需预约',
    over: '展览已结束',
    wechat: '微信公众号',
    web: '新浪微博',
    pho: '咨询电话',
    account: '网站总访问量',
    toacc: '今日访问量',
    bq: '潜山博物馆版权所有',
    num: '皖ICP备15001577号-1',
    gaba:'公安机关备案： 34082402000025号',
    mes: '未经允许不得擅自盗用本网站照片或文字，否则追究法律责任',
    tiele: '标题',
    yeartxt: '年份',
    searchtxt: '搜索',
    onlinePush: '发布留言',
    onlineContent: '留言内容',
    inputOnlineContent: '请输入留言内容',
    letterType: '信件类型',
    searchOnline: '请输入关键字搜索留言内容...',
    ziXun: '咨询',
    touSu: '投诉',
    jianYi: '建议',
    juBao: '举报',
    name: "名称",
    era: "年代",
    texture: "质地",
    classification: "分类",
    ay: '不限年份',
    details: "详情",
    gl: '地理位置',
    mc: "点击最多",
    cm: "收藏最多",
    size: "尺寸",
    introduction: '简介',
    rcr: '相关文物',
    cb: "换一批",
    phone: '联系电话',
    inputpho:'请输入联系电话',
    time: '展出时间',
    dz: '展出地点',
    pro: '展览简介',
    zp: '重点展品',
    sj: '策展手记',
    nj: '展厅内景',


    picture: '图片',
    pic: '文物照片',
    audio: '音频',
    video: '视频',
    shareTo: '分享至',
    introduce: "介绍",
    pd: '产品展示',
    solution: '解决方案',
    // ts: "技术支持",
    suggestions: '投诉建议',
    jd: '监督电话',
    dx: '电子信息',
    dw: '党务公开',
    newsd: "新闻详情",
    more: '更多',
    djdt: '党建动态',
    lookmore: '查看更多',
    ms: '位于长江中下游的安徽，山水辉映、钟灵毓秀，物华天宝、人杰地灵，是中华文明的发祥地之一。万年以来，积极进取、包容创新、担当奉献的江西人将这片神奇的土地开拓成为经济发达之区、人文鼎盛之地、文章节义之邦，谱写了人类文明发展史上浓墨重彩的绚丽篇章。',
    anhui: '安徽省博物馆召开工会委员会换届选举大会',
    ad: '潜山博物馆藏品征集公告',
    ww: '捐赠文物',
    tit: '2020年课题',
    act: '活动未开始',
    ing: '活动进行中',
    end: '活动已结束',
    activityObject: '活动对象',
    activtyTime: '活动时间',
    activtyPlace: '活动地点',
    activtyApply: '活动报名',
    floor: '楼',
    mf: '开放时间',
    rg: '入馆',
    tz: '停止入馆',
    bg: '闭馆',
    ph: '预约/投诉电话',
    yy: '预约电话',
    ts: '投诉电话',
    gr: '个人预约参观',
    td: '团队预约参观',


  },

  // 详情模块
  de: {
    rt: '发布时间',
    source: '信息来源',
    vc: '浏览次数',
    sc: '成功案例',
    particulars: '详情',
    collect: "收藏",
    check: "查看",
    download: "下载",
    cd:'出土地',
    lb:'类别',
    jb:'级别',
    zb:'招投标类',
    bw:'博物馆类',
    zf:'相关政府部门',
    zy:'公共资源交易中心',
    xz:'资源下载类',
    qt:'其它',
    sy:'首页展示',

  },

  //分页
  paging: {
    last: '上一页',
    next: '下一页',
    go: '到第',
    zhgo: '确定',
    page: '页',
    toPage: '总页数'
  },
  //志愿者信息
  part: {
    bwg:'博物馆之友申请表',
    mes: '请确保您提供了完整且准确的资料，以便您能及时收到会员活动信息及获得相应的服务。',
    lz:'现在住址或工作单位',
    inputlz:'请输入现在住址或工作单位',
    inputqt: '请输入其它',
    inputqm: '请输入申请人签名',
    ah:'您的兴趣爱好',
    inputah:'请输入您的兴趣爱好',
    dxy:'您最乐意用以下哪种方式接收我们的服务及信息？（单选）',
    yd:'信件邮递',
    dy:'电子邮件',
    dx:'手机短信',
    qq:'QQ群',
    wb:'微博',
    wx:'微信',
    qm:'申请人签名',
    qt:'其它',
    download:'附件下载',
    upload:'附件上传',
    in: '我要申请志愿者',
    insmall: '我要申请小志愿者',
    dt: '志愿者动态',
    all: '查看全部',
    tit: '欢迎您申请加入潜山博物馆普通志愿者',
    smalltit: '欢迎您申请加入潜山博物馆小志愿者',
    mess: '请确保您提供了完整且准确的资料，以便您能及时收到志愿者信息及获得相应的服务。',
    bt: '以下带※号为必填项',
    name: '姓名',
    inputna: '请输入姓名',
    inputpho: '请输入手机号',
    phone:'手机号',
    male: '性别',
    inputmale: '请输入性别',
    man: '男',
    woman: '女',
    mz: '民族',
    inputmz: "请输入民族",
    bir: "出生年月",
    inputbir: '请输入出生年月',
    from: '籍贯',
    inputfrom: '请输入籍贯',
    num: "身份证号",
    inputnum: "请输入身份证号",
    reinputnum: '填写身份证号码有误,请重新填写',
    add: '通讯地址',
    inputadd: '请输入通讯地址',
    address:'现居住地详细地址',
    inputaddress:'请输入现居住地详细地址',
    email:'电子邮件',
    inputemail:'请输入电子邮件',
    guardianname:'监护人姓名',
    inputguardianname:'请输入监护人姓名',
    gurid:'监护人身份证号码',
    inputgurid:'请输入监护人身份证号码',
    relation:'与监护人的关系',
    inputrelation:'请输入与监护人的关系',
    gurpho:'监护人联系电话',
    inputgurpho:'请输入监护人联系电话',
    grade:'学校年级',
    inputgrade:'请输入学校年级',
    work: '工作单位',
    inputwork: '请输入工作单位',
    inputqq: '请输入qq',
    reinputqq: '填写QQ号码有误,请重新填写',
    zw: '职务',
    inputzw: '请输入职务',
    wh: '文化程度',
    inpuywh: '请填写文化程度,请重新填写',
    cz: '初中',
    gz: "高中",
    dz: "大专",
    bk: '本科',
    ss: '硕士',
    bs: '博士',
    gw: '服务岗位',
    inputgw: '请输入服务岗位',
    sj: '服务时间',
    inputgwsj: '请选择服务时间',
    td: '个人简历及专长',
    inputtd: '请输入个人简历及专长',
    by: '曾受过何种奖励',
    inputjl: '请简述你获得过的奖励',
    fw: '志愿服务经历',
    inputfw: '请简述你的志愿服务经历',
    jl: '请填写个人简历',
    readL: '我已阅读',
    gl: '请勾选志愿者管理制度',
    zc: '潜山博物馆志愿者章程',
    xz: '潜山博物馆志愿者管理制度实施细则',
    tj: '提交',
    qx: '取消',
    Monday: '周一',
    Tuesday: '周二',
    Wednesday: '周三',
    Thursday: '周四',
    Friday: '周五',
    Saturday: '周六',
    Sunday: '周日'


  },
  self: {
    month: '月',
    day: '日',
    gozc: "去注册",
    nom: '忘记密码',

    hy: '注册会员',
    register: '注册',

    gr: '个人中心',
    xg: '修改头像',
    yh: '用户名',
    fillyh: '请输入用户名',
    zl: '证件类型',
    zh: '证件号码',
    sj: '手机号码',
    yx: '邮箱',
    nl: '年龄',
    bc: '保存',
    oldm: '原密码',
    newm: '新密码',
    fillm: '请输入用户密码',
    suren: '确认密码',
    surem: '确认新密码',
    fillema: '请输入验证码',
    log: '会员登录',
    login: '登录',
    out: '退出',
    mm: '密码',

    dess: '用户名可包含大写、小写英文字母，数字，中文，下划线，长度为2至18位',
    mess: '密码同时包含大写、小写英文字母，数字，下划线，长度为6至16位',
    ger: '个人预约',
    td: '团队预约',
    tz: '特展预约',
    pho: '预约号',
    per: '预约人',
    date: '参观日期',
    time: '入馆时间',
    phon: '身份证号',
    findm: '找回密码',
    yzm: ' 验证码',
    yz: '请通过邮箱验证，设置新密码',
    fail: '邮箱号填写错误',
    codeno: '验证码填写错误',
    nooldm: '密码同时包含大写、小写英文字母，数字，下划线，长度为6至16位',
    nosurem: '确认密码填写错误',
    nosame: '两次密码输入不一致,请填写一致',
    succm: '密码修改成功',
    same: '和密码保持一致',
    find: '用于找回密码',
    yhfail: '用户名填写有误,请重新填写',
    mfail: "密码填写有误,请重新填写",
    fill: '请填写验证码',
    phofail: '电话号码填写有误,请重新填写',
    xy: '请勾选协议',
    zc: '请填写以下信息注册成为会员',
    des: ' 您填写的个人资料受到潜博网站的保护',
    email: '请填写邮箱验证码',
    hq: '获取验证码',
    succ: '邮箱验证码发送成功,请注意查收!',
    inf: '基本资料',
    mx: "密码修改",
    rec: "个人预约记录",
    recs: '团队预约记录',
    sc: "我的收藏",
    chose: '选择日期',
    xx: '填写信息',
    yy: '完成预约',
    xz: '选择时间',
    sp: '余票',
    end: '已结束',
    ym: '约满',
    xj: '新建参观团队',
    xh: '序号',
    num: "团队人数",
    cz: '操作',
    delete: '删除',
    syb: '上一步',
    xyb: '下一步',
    hz: '雄风盛歌——周秦汉唐文明菁华展',
    tim: '展览时间：2021年7月06日（周二） - 10月10日（周日）',
    xt: '新增参观团队',
    cg: '新增参观者',
    total: '团队总人数',
    xm: '请填写姓名',
    sf: '身份证填写有误,请重新填写',
    jg: '军官证填写有误,请重新填写',
    tb: '台胞证填写有误,请重新填写',
    ga: '港澳证填写有误,请重新填写',
    ty: '请填写预约团队人数',
    suc: '恭喜您预约成功!',
    messa: '您预约了1人进行博物馆参观，预约信息为：',
    pz: '请主动保留或截图二维码到您的手机中，以便尽快检票入馆！',
    norep: '已预约,请勿重复预约',
    failed: '预约失败',
    lxr: '新建联系人',
    page: '数字化页面',
    ts: '选择预约时间不能小于当前时间',
    mes: '信息',
    xzl: '请选择联系人',
    success: '预约申请成功',
    old: '原密码填写有误,请重新填写',
    new: '新密码填写有误,请重新填写',
    qr: '确认密码填写有误,请重新填写',
    bg:'保存成功',
    succe:' 注册成功',


  }

}
