<template>
  <div class="top-box" v-if="topState&&!isMobile()">
      <div class="top" @click="goTop">
         <img class="top-img" src="../../static/img/go_top.png"  alt="图片" >
      </div>
  </div>
</template>

<script>
  export default {
    name:'go_top',
    data(){
      return {
          topState:false,
          topNum:0,
          timer:null
      }
    },
    mounted () {
      window.addEventListener('scroll', this.scrollToTop);
    },
    destroyed () {
      clearInterval(this.timer)
      window.removeEventListener('scroll', this.scrollToTop);
    },
    methods:{
      goTop(){
         const that= this;
               let speed = -50;
             if(this.topNum > 3000){
                speed = Math.floor(-this.topNum/25)
             }
        this.timer = setInterval(()=>{
            document.documentElement.scrollTop = document.body.scrollTop = that.topNum+ speed;
            if(that.topNum==0){
              clearInterval(that.timer)
            }

        },16)
      },
      scrollToTop(){
         let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
          this.topNum = scrollTop;
          // console.log(scrollTop)
          if (scrollTop >800) {
            this.topState = true
          } else {
            this.topState = false
          }

      }
    }

  }
</script>

<style lang="less" scoped>
  .top-box{
      position: fixed;
      right: 40px;
      bottom: 200px;
      width: 40px;
      height:140px;
      z-index: 99;
      .top{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
        // .top-img{
        //   width: 100%;
        //   height: 100%;
        // }
        // background-color: aqua;
      }
  }
</style>
